<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      :color="background"
      elevation="1"
      height="80"
    >
      <router-link
        tag="span"
        to="/"
        class="mx-1"
      >
        <base-img
          :src="require('@/assets/logo.svg')"
          class="mr-3 "
          contain
          max-width="52"
          width="100%"
        />
      </router-link>
      <router-link
        tag="span"
        to="/"
        class="mx-1"
      >
        <base-heading
          class="mt-4"
          mobile-size="text-h5"
          title="Aptamimetics"
        />
      </router-link>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        'Home',
        'About Us',
        'Technology',
        'Products',
        'Contact',
        // 'About',
        // 'Marketing',
        // 'Gallery',
        // 'Pricing',
        // 'News',
        // 'Sink',
        // 'Imprint',
      ],
    }),
    computed: {
      background () {
        const theme = this.$vuetify.theme.dark ? 'dark' : 'light'

        return this.$vuetify.theme.themes[theme].background
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
